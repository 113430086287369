<template>
    <layout-full-width :title="$tc('process-schedule', 1)">
        <component-actions :action-filters="[]"
                           :general-search-fields="generalSearchFields"
                           :itemsPerPage="itemsPerPage"
                           :page="page"
                           :primary-actions="[]"
                           :total="total"
                           @applyFilters="onApplyFilters"
                           @changeItemsPerPage="onChangeItemsPerPage"
                           @paginate="onChangePage"
                           @refresh="listProcessSchedules"/>

        <div class="row">
            <div class="col-12">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                        <component-table-header :heading-columns="headingColumns"
                                                :select-all-status="getSelectAllStatus()"
                                                :show-checkbox="true"
                                                @sort="onSortProcessSchedules"
                                                @toggleCheckbox="toggleCheckbox"/>
                        </thead>
                        <tbody>
                        <template v-if="processSchedules.list.length > 0 && !loading.PROCESS_SCHEDULE_LIST">
                            <tr v-for="(processSchedule, i) in processSchedules.list"
                                :key="processSchedule.processScheduleUniqueId">
                                <td class="align-middle">
                                    <button class="btn btn-checkbox" @click="toggleCheckbox(i)">
                                        <font-awesome-icon :icon="processSchedule.selected ? ['fas', 'check-square'] : ['far', 'square']"
                                                           class="pe-none"
                                                           :class="processSchedule.selected ? 'text-primary' : ''"/>
                                    </button>
                                </td>
                                <td class="align-middle">
                                    {{ processSchedule.processScheduleDescShort }}
                                </td>
                                <td class="align-middle">
                                    <span :class="typeClass(processSchedule.processScheduleType)" class="badge">
                                        {{ $t('types.processSchedule.' + processSchedule.processScheduleType) }}
                                    </span>
                                </td>
                                <td class="align-middle">
                                    <span :class="statusClass(processSchedule.processScheduleStatus)" class="badge">
                                        {{ $t('statuses.processSchedule.' + processSchedule.processScheduleStatus) }}
                                    </span>
                                </td>
                                <td>
                                    <template v-if="processSchedule.meta_update_dt">
                                        {{ processSchedule.meta_update_dt | timestamp }}
                                    </template>
                                    <template v-else>
                                        {{ processSchedule.meta_insert_dt | timestamp }}
                                    </template>
                                </td>
                            </tr>
                        </template>
                        <template v-if="processSchedules.list.length === 0 && !loading.PROCESS_SCHEDULE_LIST">
                            <tr>
                                <td colspan="99">
                                    <div
                                        class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                        role="alert">
                                        <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                        <div class="mt-2 fs-4">
                                            {{ $t('pages.process-schedule.no-process-schedules-found') }}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-if="loading.PROCESS_SCHEDULE_LIST">
                            <tr v-for="n in itemsPerPage" :key="'row-shimmer-' + n">
                                <td class="align-middle">
                                    <component-shimmer width="130px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="57px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="50px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="120px"/>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </layout-full-width>
</template>

<script>
import LayoutFullWidth from "@/layouts/LayoutFullWidth";
import ComponentActions from '@/components/ui/ComponentActions.vue';
import ComponentShimmer from "@/components/ComponentShimmer";
import ComponentTableHeader from '@/components/ui/ComponentTableHeader.vue';

export default {
    name: "PageProcessSchedule",
    components: {ComponentShimmer, LayoutFullWidth, ComponentActions, ComponentTableHeader},
    data() {
        return {
            filter: {},
            order: {meta_insert_dt: "DESC"},
            generalSearchFields: ['gen_process_schedule_desc_short'],
            page: 1,
            itemsPerPage: 10,
            total: 0,
            headingColumns: [
                {
                    title: this.$tc('name', 1),
                    sortField: 'gen_process_schedule_desc_short'
                },
                {
                    title: this.$tc('type', 1),
                },
                {
                    title: this.$tc('status', 1),
                },
                {
                    title: this.$t('modified')
                }
            ],
            get: false
        }
    },
    computed: {
        processSchedules() {
            return this.$store.state.PROCESS_SCHEDULE.processSchedules
        },
        processSchedule() {
            return this.$store.state.PROCESS_SCHEDULE.processSchedule
        },
        loading() {
            return {
                PROCESS_SCHEDULE_LIST: this.$store.state.loading.PROCESS_SCHEDULE_LIST
            }
        }
    },
    methods: {
        listProcessSchedules() {
            this.$store.dispatch('PROCESS_SCHEDULE/LIST', {
                filter: this.filter,
                order: this.order,
                page: this.page,
                itemsPerPage: this.itemsPerPage,
                limit: this.limit,
            })
                .then(processSchedules => {
                    this.total = processSchedules.total;
                });
        },
        async toggleCheckbox(i) {
            if (i === 'ALL') {
                const getSelectAllStatus = this.getSelectAllStatus();
                this.processSchedules.list.forEach(processSchedule => processSchedule.selected = !getSelectAllStatus);
            } else {
                this.processSchedules.list[i].selected = !this.processSchedules.list[i].selected;
            }
            this.$forceUpdate();
        },
        getSelectAllStatus() {
            const processScheduleCount = this.processSchedules.list.length;
            let selectedprocessScheduleCount = 0;
            this.processSchedules.list.forEach(processSchedule => {
                if (processSchedule.selected) {
                    selectedprocessScheduleCount += 1;
                }
            });
            return processScheduleCount === selectedprocessScheduleCount && processScheduleCount !== 0;
        },
        onChangePage(page) {
            if (page <= 0) {
                return;
            }

            this.page = parseInt(page, 10);
            this.listProcessSchedules();
        },
        onChangeItemsPerPage(itemsPerPage) {
            if (this.itemsPerPage === itemsPerPage) {
                return;
            }

            this.page = 1;
            this.itemsPerPage = itemsPerPage;
            this.listProcessSchedules();
        },
        onApplyFilters(filters) {
            this.filter = filters;
            this.listProcessSchedules();
        },
        onSortProcessSchedules(order) {
            this.order = order;
            this.listProcessSchedules();
        },
        typeClass(type) {
            return {
                'bg-indigo': type === 'PROCESS_SCHEDULE_WORKFLOW'
            }
        },
        statusClass(status) {
            return {
                'bg-info': status === 'PROCESS_SCHEDULE_ACTIVE',
                'bg-light': status === 'PROCESS_SCHEDULE_INACTIVE'
            }
        },
    },
    beforeMount() {
        this.listProcessSchedules();
    }
}
</script>

<style lang="scss" scoped>
</style>
